import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    bankEntries: [],
    meta: { currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0 },
};
// ----------------------------------------------------------------------
export const uploadBankEntries = createAsyncThunk('bankEntries/uploadBankEntries', async ({ file, bankAccountId }, thunkAPI) => {
    let data;
    try {
        const formData = serialize({
            file,
            bankAccountId,
        });
        const response = await axios.post(`/bankEntries`, formData);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getBankEntries({ bankAccountId: bankAccountId?.toString() }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getBankEntries = createAsyncThunk('bankEntries/getBankEntries', async ({ page, rowsPerPage = 5, orderBy = 'createdAt', order = 'desc', isPaging = true, bankAccountId = '', }) => {
    let data;
    const queryParams = {
        search: {
            bankAccountId,
            'invoice.bankEntryId': null,
            'expenses.bankEntryId': null,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    const url = createDynamicURL('/bankEntries', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const linkBankEntry = createAsyncThunk('bankEntries/linkBankEntry', async ({ id, entityIds }, thunkAPI) => {
    let data;
    try {
        const response = await axios.put(`/bankEntries/${id}/link`, { entityIds });
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteBankEntry = createAsyncThunk('bankEntries/deleteBankEntry', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/bankEntries/${id}`);
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getBankEntries({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteManyBankEntries = createAsyncThunk('bankEntries/deleteManyBankEntries', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/bankEntries/many`, { data: { ids } });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getBankEntries({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'bankEntry',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBankEntries.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getBankEntries.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.bankEntries = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getBankEntries.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice.reducer;
